import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CLocalBtnList,
  CSectTitle03,
  CFaqList,
  CBreadCrumb,
  LFaqContact,
  CAnchorNav,
} from '../../components/_index';
import '../../assets/_sass/page/faq.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        type="ja"
        data={{
          title: {
            main: (
              <>
                よくあるご質問／
                <br className="u_sp" />
                お問合せ
              </>
            ),
            sub: 'FAQ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_faq">
        <section className="u_mtMedium">
          <LWrap>
            <CLocalBtnList
              data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: '/faq/',
                  },
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: '/faq/restaurants/',
                  },
                },
                {
                  label: 'ウエディング',
                  link: {
                    href: '/faq/wedding/',
                  },
                },
                {
                  label: '宴会・会議',
                  link: {
                    href: '/faq/banquet/',
                  },
                },
                {
                  label: 'お子様連れのお客様',
                  link: {
                    href: '/faq/child/',
                  },
                },
                {
                  label: 'バリアフリー',
                  link: {
                    href: '/faq/accessibility/',
                  },
                },
              ]}
            />
          </LWrap>
          {/* <LWrap size="small">
            <CAnchorNav
              exClass="u_mt50"
              data={[
                {
                  title: <>基本情報</>,
                  link: {
                    href: '#basic_info',
                  },
                },
                {
                  title: <>客室</>,
                  link: {
                    href: '#rooms',
                  },
                },
                {
                  title: <>食事</>,
                  link: {
                    href: '#restaurants',
                  },
                },
                {
                  title: <>お支払い</>,
                  link: {
                    href: '#payment',
                  },
                },
                {
                  title: <>お子様連れ</>,
                  link: {
                    href: '#child',
                  },
                },
                {
                  title: <>設備・サービス</>,
                  link: {
                    href: '#facility',
                  },
                },
                {
                  title: <>アクセス</>,
                  link: {
                    href: '#access',
                  },
                },
                {
                  title: <>その他</>,
                  link: {
                    href: '#other',
                  },
                },
              ]}
            />
          </LWrap> */}
        </section>

        <section className="u_pt80" id="basic_info">
          <LWrap>
            <CSectTitle03 title="基本情報" />
            <CFaqList
              data={[
                {
                  q: 'サービス料はいくらですか?',
                  a: <>本体価格の15%をいただいております。</>,
                },
                {
                  q: 'チェックイン・チェックアウトについて教えてください。',
                  a: (
                    <>
                      チェックインは15:00、チェックアウトは12:00（正午）となります。
                      <br />
                      ・ご到着が遅れる場合
                      <br />
                      ご予約の際お申し出いただいた到着予定時刻より大幅に遅れる場合はご連絡ください。フロント、正面玄関は24時間ご利用いただけます。
                      <br />
                      ・15:00より前のチェックイン（有料）を希望される場合
                      <br />
                      当日のお部屋の状況によりますためリクエストとして承ります。時間に規定のあるプランの場合はお受けできません。
                      <br />
                      宿泊営業課：TEL 03-5641-2958（受付時間：平日
                      8:00~20:00／土・日・祝日 9:00~18:00）
                      <br />
                      ・12:00（正午）より後のチェックアウト（有料）を希望される場合
                      <br />
                      当日のお部屋の状況によりますが、15:00までの延長を承ります。15:00以降は1泊分の追加料金を申し受けます。
                    </>
                  ),
                },
                {
                  q: '宿泊の前に荷物をホテルに送りたいのですが。',
                  a: (
                    <>
                      ご宿泊者名・ご宿泊日を伝票に明記の上、下記までお送りください。
                      <br />
                      〒103-8520
                      <br />
                      東京都中央区日本橋蛎殻町2-1-1
                      <br />
                      ロイヤルパークホテル
                      フロント気付+「ご宿泊者名（カタカナ）・ご宿泊日」
                      <br />
                      TEL 03-3667-1111（代表）
                      <br />
                      ※お荷物はご予約後にお送りくださいますようお願いいたします。
                      <br />
                      ※冷蔵・冷凍品・着払いはお受けできません。
                      <br />
                      ※発送された際のお控えは念のためお手元にお持ちください。
                    </>
                  ),
                },
                {
                  q: 'チェックイン前、チェックアウト後に荷物を預けられますか?',
                  a: (
                    <>
                      当日早めにご到着の場合、チェックイン前でもお荷物をお預かりいたします。お気軽にフロントのスタッフへお声掛けください。
                      <br />
                      また、チェックアウト後も当日中に限りお預かりいたします。ご貴重品は必ずご自身でお持ちください。
                    </>
                  ),
                },
                {
                  q: '同伴者が先にチェックインできますか?',
                  a: (
                    <>
                      お手続きいただけますが、精算がお済みでない場合はチェックイン時にお支払いいただく旨をあらかじめご了承ください。
                    </>
                  ),
                },
                {
                  q: '未成年者だけで宿泊できますか?',
                  a: (
                    <>
                      未成年の方だけでご宿泊する場合は、保護者の方の同意が必要です。
                      <br />
                      下記より同意書をダウンロードいただき、ご記入・捺印の上、チェックインの際にご提出ください。
                      <br />
                      <a
                        href="https://www.royalparkhotels.co.jp/assets/files/pdf/agreement_underage.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ＞保護者同意書<i className="icon-document"></i>
                      </a>
                    </>
                  ),
                },
                {
                  q: '事前に客室番号を教えてもらうことはできますか?',
                  a: <>ご到着以前の客室番号のご案内はいたしておりません。</>,
                },
                {
                  q: 'ベストレート保証の対象となる条件は何ですか?',
                  a: (
                    <>
                      対象条件は
                      <a
                        href="https://www.royalparkhotels.co.jp/about/bestrate/"
                        target="_blank"
                      >
                        こちら
                      </a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'キャンセル料はかかりますか?',
                  a: (
                    <>
                      宿泊約款に基づき、ご宿泊予約後のキャンセルに関しては、以下の通りキャンセル料を申し受けます。
                      <br />
                      ご到着日の7～2日前・・・40%
                      <br />
                      ご到着日の前日・・・・・80%
                      <br />
                      ご到着日の当日・・・・・100%
                      <br />
                      ご連絡なしの不泊・・・・100%
                      <br />
                      ※宿泊プランによって内容が異なる場合がございますので、ホテルへご確認ください。
                      <br />
                      ※旅行代理店、旅行予約サイト経由でのご予約の場合は、内容が異なる場合がございますので、
                      <br />
                      　ご予約された旅行代理店、旅行予約サイトにてご確認ください。
                    </>
                  ),
                },
                {
                  q: 'キャンセル待ちの予約はできますか?',
                  a: (
                    <>
                      申し訳ございませんが、キャンセル待ちのご予約は承っておりません。
                      <br />
                      ご連絡いただければ、その都度の空室状況をご案内させていただきます。
                    </>
                  ),
                },
                {
                  q: '旅行会社、旅行予約サイト経由で予約をしましたが、 変更・キャンセルできますか?',
                  a: (
                    <>
                      ご予約された旅行会社または旅行予約サイトから、ご変更・キャンセルのお手続きをお願いいたします。
                    </>
                  ),
                },
                {
                  q: '宿泊している方と会いたいのですが、エグゼクティブラウンジに訪問することはできますか?',
                  a: (
                    <>
                      スイート、エグゼクティブフロアにご宿泊の方をご訪問されるお客様は、エグゼクティブラウンジを有料でご利用いただけます。
                      <br />
                      朝食 4,000円、ティータイム 3,500円、カクテルタイム 9,000円
                      <br />
                      ※料金には消費税・サービス料が含まれます。
                    </>
                  ),
                },
                {
                  q: 'エグゼクティブラウンジは子供も利用できますか?',
                  a: (
                    <>
                      朝食、ティータイムは、添い寝のお子様は無料でご利用いただけます。
                      <br />
                      17:00以降のカクテルタイムにつきましては、添い寝の有無にかかわらず小学生以下のお子様のご入場をご遠慮いただいております。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="rooms">
          <LWrap>
            <CSectTitle03 title="客室" />
            <CFaqList
              data={[
                {
                  q: 'アメニティや貸出品にはどのようなものがありますか?',
                  a: (
                    <>
                      <a href="/stay/floor/suite/">スイート</a>、
                      <a href="/stay/floor/club_floor/">エグゼクティブフロア</a>
                      、
                      <a href="/stay/floor/standard_floor/">
                        スタンダードフロア
                      </a>
                      により異なります。
                      <br />
                      詳細は各ページをご確認ください。
                    </>
                  ),
                },
                {
                  q: '貴重品の保管について教えてください。',
                  a: <>客室内設置のセーフティボックスをご利用ください。</>,
                },
                {
                  q: '客室でマッサージをお願いできますか?',
                  a: (
                    <>
                      リラクゼーションサービスがございます。詳細は
                      <a
                        href="/assets/files/pdf/relaxation_service.pdf"
                        target="_blank"
                      >
                        こちら
                      </a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '1室あたり最大何名まで宿泊することができますか?',
                  a: (
                    <>
                      部屋タイプによって異なりますが、最大3名様までご宿泊いただけます。なお、3名様でご利用いただく場合は、ツインルームにエキストラベッドを1台ご用意いたします。
                    </>
                  ),
                },
                {
                  q: '客室に飲料水はありますか？また氷はもらえますか?',
                  a: (
                    <>
                      無料のミネラルウォーターをご用意しております。氷をご希望の際はルームサービスへご連絡ください。
                    </>
                  ),
                },
                {
                  q: '加湿器や空気清浄機は常設されていますか?',
                  a: <>空気清浄機（加湿機能付）を全室に常設しております。</>,
                },
                {
                  q: 'DVDは視聴可能でしょうか?ディスクの貸し出しはありますか?',
                  a: (
                    <>
                      DVDプレーヤーの貸し出しがございますが、ディスクの貸し出しはございません。
                    </>
                  ),
                },
                {
                  q: 'ベッドタイプ、高層階などの希望はできますか?',
                  a: (
                    <>
                      ベッドタイプは、ご予約の際にご相談ください。また高層階などのご希望は、リクエストにて承ります。
                    </>
                  ),
                },
                {
                  q: 'コネクティングルームはありますか?',
                  a: (
                    <>
                      スタンダードツインの部屋タイプにございます。お部屋の数に限りがありますのでホテルへお問合せください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="restaurants">
          <LWrap>
            <CSectTitle03 title="食事" />
            <CFaqList
              data={[
                {
                  q: '食物アレルギーがあるのですが、どのような対応していただけますか?',
                  a: (
                    <>
                      皆様にお食事をお楽しみいただけるよう、できる限りの対応をいたしております。
                      <br />
                      食物アレルギーをお持ちの方へのご案内は
                      <a href="/restaurants/allergy/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '朝食の予約はできますか?',
                  a: (
                    <>
                      ご宿泊者様はルームサービスのみ、前日にご予約を承ります。客室のお電話よりご連絡ください。
                    </>
                  ),
                },
                {
                  q: '朝食の時間と場所を教えてください。',
                  a: (
                    <>
                      朝食のご案内は<a href="/stay/breakfast/">こちら</a>
                      をご確認ください。
                      <br />
                      プランにより異なりますので、詳しくはホテルへお問合せください。
                    </>
                  ),
                },
                {
                  q: 'ルームサービスの営業時間やメニューを教えてください。',
                  a: (
                    <>
                      ルームサービスのご案内は
                      <a href="/private/3mchpxdzfuxz/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="payment">
          <LWrap>
            <CSectTitle03 title="お支払い" />
            <CFaqList
              data={[
                {
                  q: '当日の支払いは、現金以外にどのような決済方法がありますか?',
                  a: (
                    <>
                      キャッシュレス決済につきましては、以下の通りご利用が可能です。
                      <br />
                      ・クレジットカード
                      <br />
                      　American
                      Express／VISA／DC／Diners／JCB／Master／UFJ／Nicos／銀聯カード
                      <br />
                      ・二次元バーコード決済
                      <br />
                      　ALIPAY／WeChatPay／メルペイ／楽天ペイ／PayPay／d払い／auPay／Bank Pay／J-Coin Pay
                    </>
                  ),
                },
                {
                  q: '商品券やギフト券は使えますか?',
                  a: (
                    <>
                      以下の商品券、ギフト券をご利用いただけます。
                      <br />
                      三菱UFJニコスギフトカード／JCBギフトカード／三菱地所グループ共通ギフトカード／ロイヤルパークホテルズギフト券
                      <br />
                      そのほかにつきましてはお問合せください。なお、券面金額未満のお支払いの場合はおつりが出ません。
                    </>
                  ),
                },
                {
                  q: '宿泊料金はいつ払うのですか?',
                  a: (
                    <>
                      事前決済がお済みでないお客様は、チェックインの際にお支払いをお願いいたします。
                    </>
                  ),
                },
                {
                  q: 'デポジットは必要ですか?',
                  a: (
                    <>
                      必要な場合がございますので、詳細はホテルへお問合せください。
                    </>
                  ),
                },
                {
                  q: '知人を招待したいのですが、事前に支払いはできますか?',
                  a: (
                    <>
                      銀行振込、ご来館でのお支払い、オンラインでのクレジットカード決済を承ります。
                      <br />
                      事前精算をご希望の場合は、お支払い範囲やお支払い日等を確認させていただきますので、宿泊営業課までお問合せください。
                      <br />
                      宿泊営業課：TEL 03-5641-2958（受付時間：平日
                      8:00~20:00／土・日・祝日 9:00~18:00）
                    </>
                  ),
                },
                {
                  q: '後日の支払いはできますか?',
                  a: (
                    <>
                      後日のお支払いは承っておりませんので、事前または当日のお支払いをお願いいたします。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="child">
          <LWrap>
            <CSectTitle03 title="お子様連れのお客様" />
            <CFaqList
              data={[
                {
                  q: 'おむつ替えのできる場所はありますか?',
                  a: (
                    <>
                      下記をご利用いただけます。
                      <br />
                      ベビーベッド：1F ロビー
                      フロントカウンター横（授乳室併設、ご利用の際はスタッフにお申し付けください）
                      <br />
                      折りたたみシート：1F バリアフリートイレ内
                      <br />
                      おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                    </>
                  ),
                },
                {
                  q: '授乳室はありますか?',
                  a: (
                    <>
                      1F ロビー
                      フロントカウンター横にご用意しております（ご利用の際はスタッフにお申し付けください）。
                    </>
                  ),
                },
                {
                  q: '子供が一緒の場合、追加料金はかかりますか?',
                  a: (
                    <>
                      小学生以下のお子様は、添い寝の場合に限り無料です。詳細は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                      <br />
                      エグゼクティブラウンジのご利用（スイート、エグゼクティブフロアご宿泊者のみ）は、17:00以降小学生以下のお子様のご入場をご遠慮いただいております。
                    </>
                  ),
                },
                {
                  q: '朝食付プランで申し込んだ場合、添い寝の子供の朝食はどうなりますか?',
                  a: (
                    <>
                      別途ご料金を頂戴しております。お子様連れのお客様へのご案内は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: '子供向けの備品やサービスはありますか?',
                  a: (
                    <>
                      ベビーベッド、アメニティ、貸出備品のご用意がございます。
                      <br />
                      お子様連れのお客様へのご案内は
                      <a href="/stay/child/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ミルクの温めや哺乳瓶の消毒はできますか?',
                  a: (
                    <>
                      ・ミルク用のお湯：お部屋に備え付けの電気ケトルをご利用いただけます。
                      <br />
                      ・哺乳瓶の消毒や、離乳食などの温め：1Fに電子レンジコーナーをご用意しております。
                      <br />
                      もしくはルームサービスでも、お預かりさせていただきご対応を承ります。哺乳瓶の消毒には、電子レンジ対応の消毒キットをご持参ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="facility">
          <LWrap>
            <CSectTitle03 title="設備・サービス" />
            <CFaqList
              data={[
                {
                  q: 'ホテル内にコンビニはありますか?',
                  a: <>ホテル内にはございませんが、近隣に数店舗ございます。</>,
                },
                {
                  q: '喫煙できる場所はありますか?',
                  a: (
                    <>
                      B1F
                      、2F、3Fに喫煙コーナーをご用意しております。電子タバコ、加熱式タバコも喫煙コーナーをご利用ください。
                      <br />
                      ホテル館内は客室内を含め全て禁煙でございます。
                    </>
                  ),
                },
                {
                  q: 'インターネットの利用・接続は可能でしょうか?',
                  a: (
                    <>
                      Wi-Fi接続サービスを導入しており、無料でお手持ちの対応機器からインターネット接続をご利用いただけます。
                      <br />
                      ※対象機器：キャリアを問わずWi-Fi規格に適合した機器はすべてご利用可能です。
                      <br />
                      ※スタッフよりパスワードをご案内させていただきます。利用規約にご承諾いただいた後、接続が可能となります。
                    </>
                  ),
                },
                {
                  q: 'コインランドリーはありますか?',
                  a: (
                    <>
                      館内ランドリーサービス（有料）がございます。詳しくはお問合せください。
                    </>
                  ),
                },
                {
                  q: 'フィットネス施設はありますか?',
                  a: (
                    <>
                      5Fのご宿泊者専用ジム（有料）を24時間ご利用いただけます（※スイート、エグゼクティブフロアにご宿泊の方は無料）。
                      詳細は<a href="/stay/facility/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ランドリーサービスはありますか?',
                  a: (
                    <>
                      クリーニング、ドライクリーニング、プレスのランドリーサービス（全て有料）がございます。
                      客室に備え付けのランドリー伝票にご記入の上、客室係までご連絡ください。
                    </>
                  ),
                },
                {
                  q: 'パソコンの利用や、資料を印刷できるところはありますか?',
                  a: (
                    <>
                      5Fのご宿泊者専用「ビジネスサポートゾーン」に、作業スペースとプリンターがございます。詳細は
                      <a href="/stay/facility/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: '電子レンジはありますか?',
                  a: (
                    <>
                      1F ロビー
                      フロントカウンター横に電子レンジコーナーがございます。
                    </>
                  ),
                },
                {
                  q: '記念日で宿泊しますが、お花やケーキなどは用意してもらえますか?',
                  a: (
                    <>
                      花束やケーキをはじめ、各種取り揃えております。事前のご予約をおすすめいたします。
                      <br />
                      アニバーサリーのご案内は<a href="/anniversary/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ケーキの持込やプレゼントの部屋入れはできますか?',
                  a: (
                    <>
                      ホテル以外で購入された食品を客室にお持込いただくことは可能ですが、お預かりは衛生上お断りをしております。
                      <br />
                      貴重品を除くプレゼントのお預かり、およびチェックイン前にお部屋にご用意することは可能です。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="access">
          <LWrap>
            <CSectTitle03 title="アクセス" />
            <CFaqList
              data={[
                {
                  q: 'ホテルへの行き方を教えてください。',
                  a: (
                    <>
                      ホテルへのアクセスは<a href="/about/access/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'ホテルから東京シティエアターミナルへの行き方、バスの運行時間、所要時間、料金など教えてください。',
                  a: (
                    <>
                      正面玄関を出て左手の隣接している建物が東京シティエアターミナルです。ホテル2Fの専用通路もご利用いただけます。
                      <br />
                      羽田空港までの所要時間は約25分、約30分間隔で運行、成田空港までが約55分、約10分から15分間隔で運行しております。
                      <br />
                      東京シティエアターミナルの詳細は公式サイトをご確認ください。
                      <br />
                      東京シティ・エアターミナル株式会社
                      東京シティエアターミナル：
                      <a
                        href="https://www.tcat-hakozaki.co.jp/"
                        target="_blank"
                      >
                        https://www.tcat-hakozaki.co.jp/
                      </a>
                    </>
                  ),
                },
                {
                  q: '東京駅からの送迎バスはありますか?有料ですか?',
                  a: (
                    <>
                      地下鉄、タクシーなど公共交通機関をご利用ください。
                      <br />
                      また、無料巡回バスもご利用いただけます。詳細は
                      <a href="/about/tourism/">こちら</a>をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'リムジンバスの予約はできますか?',
                  a: (
                    <>
                      リムジンバスの公式サイトにて成田空港・羽田空港ともにご予約可能です。
                      <br />
                      東京空港交通株式会社 リムジンバス予約Web：
                      <a
                        href="https://www.limousinebus.co.jp/guide/reserve/"
                        target="_blank"
                      >
                        https://www.limousinebus.co.jp/guide/reserve/
                      </a>
                    </>
                  ),
                },
                {
                  q: '駐車場にEV充電設備はありますか?',
                  a: (
                    <>
                      ホテル館内にはございませんので、近隣のEV充電設備をご利用ください。
                    </>
                  ),
                },
                {
                  q: '駐車料金の優待はありますか?',
                  a: (
                    <>
                      ご利用料金やプランなどに合わせたご優待をご用意しております。
                      <br />
                      詳細は<a href="/about/access/#car">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: '宿泊時の駐車料金は?またいつまで車を停めておけますか?',
                  a: (
                    <>
                      ご1泊につき1台1,500円（消費税込）にてご利用いただけます。入庫から最大30時間お停めいただけます。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="other">
          <LWrap>
            <CSectTitle03 title="その他" />
            <CFaqList
              data={[
                {
                  q: '商品券やギフト券を購入できますか?',
                  a: (
                    <>
                      全国のロイヤルパークホテルズのご宿泊・ご飲食などにご利用いただけるギフト券を1F
                      スイーツ&ベーカリー 粋にて販売をしております。詳細は
                      <a href="/restaurants/iki/gifts/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
                {
                  q: 'バスツアーの予約をお願いできますか',
                  a: (
                    <>
                      1F ロビー
                      ベルデスクにて承ります。詳細はホテルへお問合せください。
                    </>
                  ),
                },
                {
                  q: 'ペットの同伴は可能ですか?',
                  a: (
                    <>
                      動物の種類や大きさを問わず、盲導犬、聴導犬、介助犬以外のペットのご同伴はご遠慮いただいております。
                    </>
                  ),
                },
                {
                  q: '最寄りのレンタカーショップはありますか?',
                  a: (
                    <>
                      東京シティエアターミナル B1F
                      にございます。詳細は公式サイトをご確認ください。
                      <br />
                      東京シティ・エアターミナル株式会社
                      東京シティエアターミナル：
                      <a
                        href="https://www.tcat-hakozaki.co.jp/"
                        target="_blank"
                      >
                        https://www.tcat-hakozaki.co.jp/
                      </a>
                    </>
                  ),
                },
                {
                  q: 'ナイトウェア（パジャマ）やスリッパで館内施設を利用できますか?',
                  a: (
                    <>
                      ホテル館内では、ナイトウェア（パジャマ）やスリッパでのご利用はご遠慮くださいますようお願いいたします。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LFaqContact />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
